import { Button, Grid, Typography, Stack, Box } from "@mui/material";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import img1 from "../image/preFooterMovil.png";

function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          mt: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          background: `rgba(0, 0, 0, 0.7) url(${img1})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          minHeight: "40vh",
          backgroundBlendMode: "darken",
        }}
      >
        <Typography
          variant="h4"
          color="primary"
          sx={{
            textAlign: { sm: "center" },
            fontSize: { xs: "1.125rem", sm: "x-large" },
            paddingTop: { sm: "5vh" },
            paddingBottom: { sm: "5vh" },
          }}
        >
          ¡Súmate a este crecimiento!
        </Typography>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => navigate("/contacto")}
            sx={{
              backgroundColor: "#28C23E",
              borderRadius: 25,
              px: 5,
              py: 2,
              "&:hover": { backgroundColor: "#66bb6a", borderRadius: 10 },
            }}
          >
            TOCA AQUÍ PARA EMPEZAR
          </Button>
          <Typography variant="subtitle1" color="primary" sx={{ mt: 2, paddingBottom: { sm: "10vh" } }}>
            Animate a crecer
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "column" },
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          backgroundColor: "#517417",
          py: 10,
          pb: 5,
          paddingTop: { sm: "5vh" },
          paddingLeft: { sm: "10vw" },
        }}
      >
        <Grid item>
          <Typography
            variant="h6"
            color="#fff"
            sx={{ pb: 3, fontWeight: "600", fontSize: { xs: "1rem", sm: "1.25rem" } }}
          >
            Contacto
          </Typography>
          <Typography variant="subtitle1" color="#fff" sx={{ pb: 2, fontSize: { xs: ".75rem", sm: "1.125rem" } }}>
            <Box component="span" sx={{ fontWeight: 600 }}>
              Montevideo - Uy
            </Box>
            <br />
            <LocationOnIcon color="primary" fontSize="small" sx={{ mr: 0.5 }} />
            Misiones 1537
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "flex-start",
          }}
          item
        >
          <Typography
            variant="h6"
            color="#fff"
            sx={{ pb: 3, fontWeight: "600", fontSize: { xs: "1rem", sm: "1.25rem" } }}
          >
            Páginas
          </Typography>
          <Typography
            variant="subtitle2"
            onClick={() => navigate("/quienes-somos")}
            sx={{
              textTransform: "none",
              textDecoration: "none",
              color: "#fff",
              paddingBottom: "2vh",
              cursor: "pointer",
              "&:hover": {
                color: "#fff",
                borderBottom: "1px solid #fff",
                padding: 0,
                marginBottom: "3vh",
              },
            }}
          >
            Quienes Somos
          </Typography>

          <Typography
            variant="subtitle2"
            onClick={() => navigate("/contacto")}
            sx={{
              textTransform: "none",
              textDecoration: "none",
              color: "#fff",
              paddingBottom: "2vh",
              cursor: "pointer",
              "&:hover": {
                color: "#fff",
                borderBottom: "1px solid #fff",
                padding: 0,
                marginBottom: "3vh",
              },
            }}
          >
            Contacto
          </Typography>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2} sx={{ mr: "2vw" }}>
            <FacebookIcon color="primary" sx={{ "&:hover": { color: "#3b5998" } }} />
            <InstagramIcon color="primary" sx={{ "&:hover": { color: "#d30050" } }} />
            <YouTubeIcon color="primary" sx={{ "&:hover": { color: "#ff0000" } }} />
            <LinkedInIcon color="primary" sx={{ "&:hover": { color: "#0a66c2" } }} />
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          justifyContent: "space-around",
          alignItems: "center",
          py: "1rem",
          gap: 2,
          backgroundColor: "#6A9818",
          minHeight: "15vh",
        }}
      >
        <Typography variant="subtitle1" color="primary" sx={{ fontSize: { xs: ".9rem", md: "1rem" } }}>
          {" "}
          © Derechos One Click Applications.
        </Typography>

        <Typography
          onClick={() => navigate("/politica-de-privacidad")}
          variant="subtitle1"
          color="primary"
          sx={{ fontSize: { xs: ".9rem", md: "1rem" }, textDecoration: "none", color: "#fff", cursor: "pointer" }}
        >
          Ver políticas de privacidad
        </Typography>

        <Stack direction="row" spacing={2}>
          <Typography variant="subtitle1" color="primary" sx={{ fontSize: { xs: ".9rem", md: "1rem" } }}>
            One Click Applications
          </Typography>
        </Stack>
      </Grid>
    </>
  );
}

export default Footer;
