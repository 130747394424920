import React, { useEffect, useState } from "react";
import Carrusel from "../../components/Carrusel";
import Accordion from "@mui/material/Accordion";
import { useNavigate } from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import WorkIcon from "@mui/icons-material/Work";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CheckIcon from "@mui/icons-material/Check";
import Divider from "@mui/material/Divider";
import styles from "./Home.module.css";
import "animate.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Grid, Typography, Card, CardContent, CardActions, Button, IconButton } from "@mui/material";
import MultiCarrusel from "../../components/MultiCarrusel";
import Formulario from "../../components/Formulario";
import { Helmet } from "react-helmet";
import imgDecisiones from "../../image/partners-bg.png";
import imgServicios from "../../image/page-title7-1.jpg";
import imgConfiaNosotros from "../../image/partners-bg.png";

function Home() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [counterOn, setCounterOn] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [animate2, setAnimate2] = useState(false);
  const [animate3, setAnimate3] = useState(false);
  const [animate4, setAnimate4] = useState(false);
  const [animate5, setAnimate5] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setAnimate(false);
    setAnimate2(false);
    setAnimate3(false);
    setAnimate4(false);
    setAnimate5(false);
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Helmet>
        <title>One Click Applications – People before strategy</title>
        <meta
          name="description"
          content="One Click Applications es una firma profesional, Partner de Microsoft, 
          que provee soluciones corporativas para la transformación digital de los Procesos."
        />
        <meta name="keywords" content="One Click Applications, Humanware, Humanware Applications, SGR, Microsoft, Partner de Microsoft"></meta>
      </Helmet>
      <Box>
        <Carrusel />
        <Box sx={{ mx: 1 }}>
          <Grid container direction="column" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" sx={{ color: "#1786A0", fontWeight: "bold", mt: 10, fontSize:{xs: "1rem", md:"1.25rem"} }}>
              People Before Strategy
            </Typography>
            <ScrollTrigger onEnter={() => setAnimate(true)}>
              <Typography
                className={`${animate ? "animate__animated animate__fadeInUp animate-duration: 3000ms" : ""}`}
                variant="h4"
                color="initial"
                align="center"
                sx={{ fontWeight: "bold", p: 8, color: "#414042", animationDuration: "2000ms", fontSize:{xs: "1rem", md:"1.25rem"} }}
              >
                Proveemos soluciones corporativas para la
                <br />
                transformación digital de los Procesos.
              </Typography>
            </ScrollTrigger>
            <ScrollTrigger onEnter={() => setAnimate2(true)}>
              <Typography
                className={`${animate2 ? "animate__animated animate__fadeInUp" : ""}`}
                variant="h6"
                color="initial"
                align="center"
                sx={{
                  color: "#7A7C7F",
                  fontWeight: "fontWeightMedium",
                  animationDuration: "2000ms",
                  animationDelay: "1s",
                  fontSize: {xs: "1rem", md:"1.25rem"}
                }}
              >
                En One Click Applications creemos que la Competitividad Organizacional
                <br />
                se apoya en la transformación digital con soluciones y plataformas
                <br />
                de desarrollo rápido.
              </Typography>
            </ScrollTrigger>
            <hr sx={{ color: "#1786A0" }} />
          </Grid>
          <ScrollTrigger onEnter={() => setAnimate3(true)}>
            <Grid
              container
              className={`${styles.cardContainer1}`}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ mb: 20, mt: 10, px: 2, gap: 2 }}
              
            >
              <Card
                className={`${animate3 ? "animate__animated animate__fadeInUp" : ""}`}
                sx={{
                  maxWidth: 375,
                  maxHeight: 405,
                  backgroundColor: "#517417",
                  animationDuration: "2000ms",
                  animationDelay: "1s",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ p: 4 }} color="#fff" gutterBottom>
                    <LibraryBooksIcon fontSize="large" />
                  </Typography>
                  <Typography variant="h6" component="div" color="primary" sx={{ fontSize: "larger",textAlign: "center"}}>
                    Aplicaciones
                  </Typography>
                  <Typography
                    sx={{ px: 5, pt: 3, pb: 1 }}
                    color="#fff"
                    align="center"
                  >
                    Aplicaciones Core para el negocio, como SGR One Click gestión Integral de SGRs, HR One Click gestión
                    de Recursos Humanos, QUA One Click control de calidad, CM One Click gestion construcción, Portal One
                    Clcik portales webs.
                  </Typography>
                  {/* <CardActions>
                    <Typography
                      onClick={() => navigate("/aplicaciones")}
                      variant="subtitle2"
                      sx={{ fontWeight: 700, cursor: "pointer", color: "#757575" }}
                    >
                      Ver Más <KeyboardArrowRightIcon />
                    </Typography>
                  </CardActions> */}
                </CardContent>
              </Card>
              <Card
                className={` ${animate3 ? "animate__animated animate__fadeInUp" : ""}`}
                sx={{
                  maxWidth: 375,
                  minHeight: 415,
                  backgroundColor: "#6a9818",
                  animationDuration: "2000ms",
                  animationDelay: "1.5s",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ p: 4 }} color="#fff" gutterBottom>
                    <QuestionAnswerIcon fontSize="large" />
                  </Typography>
                  <Typography variant="h6" component="div" color="primary" sx={{ fontSize: "larger",textAlign: "center"}}>
                    Corporate Performance Management
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5, px: 5, py: 3 }}
                    color="#fff"
                    align="center"
                  >
                    Corporate Performance Management con Kepion Planning y Power BI como soluciones para gestión
                    estratégica y táctica del negocio.
                  </Typography>
                </CardContent>
              </Card>
              <Card
                className={`${animate3 ? "animate__animated animate__fadeInUp" : ""}`}
                sx={{
                  maxWidth: 375,
                  maxHeight: 405,
                  backgroundColor: "#517417",
                  animationDuration: "2000ms",
                  animationDelay: "2s",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ p: 4 }} color="#fff" gutterBottom>
                    <LibraryAddCheckIcon fontSize="large" />
                  </Typography>
                  <Typography variant="h6" component="div" color="primary" sx={{ fontSize: "larger",textAlign: "center"}} >
                    Capacitaciones
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5, px: 5, py: 3 }}
                    color="#fff"
                    align="center"
                  >
                    Capacitación y desarrollo de competencias internas para la efectiva transferencia de know how en la
                    tecnología y nuestras soluciones.
                  </Typography>
                  {/* <CardActions>
                    <Typography
                      onClick={() => navigate("/capacitaciones")}
                      variant="subtitle2"
                      color="#757575"
                      sx={{ fontWeight: 700, cursor: "pointer" }}
                    >
                      Ver Más <KeyboardArrowRightIcon />
                    </Typography>
                  </CardActions> */}
                </CardContent>
              </Card>
            </Grid>
          </ScrollTrigger>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              backgroundImage: `url(${imgDecisiones})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Typography variant="h5" align="center" color="#414042" sx={{ px: 5, fontWeight: 700 }}>
                Proporcione a su empresa todo lo que <br /> necesita para triunfar
              </Typography>
              <Typography variant="subtitle1" color="#7A7C7F" sx={{ px: 5, mt: 6 }} align="center">
                Unifique toda su empresa, desde los datos hasta las personas, pasando por los
                <br />
                procesos, con aplicaciones modernas e inteligentes que se adaptan a las necesidades
                <br />
                cambiantes de su negocio
              </Typography>
            </Grid>

            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                mb: 20,
                mt: 10,
                px: 3,
                gap: 2
              }}
            >
              <Card sx={{ maxWidth: 375, maxHeight: 385, backgroundColor: "#fff" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="h2" color="#3E6369" align="left" sx={{ fontWeight: 600, px: 2, fontSize:{xs: "2rem"} }}>
                    01.
                  </Typography>
                  <Typography variant="h5" sx={{ px: 2, py: {xs: 1,md:3}, fontWeight: 600, fontSize:{xs: "1.125rem", md: "1.25rem"} }} color="#414042" align="left">
                    Tome mejores decisiones.
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 1.5, px: 2, pb: 2, fontSize:{xs: "1rem"} }} color="#414042" align="left">
                    Mejore los resultados empresariales con conocimientos y recomendaciones basados en los datos.
                  </Typography>
                  <Divider color="secondary" sx={{ marginLeft: "2vh", width: "30%" }} />
                </CardContent>
              </Card>
              <Card sx={{ maxWidth: 375, maxHeight: 385, backgroundColor: "#fff" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="h2" color="#3E6369" align="left" sx={{ fontWeight: 600, px: 2, fontSize:{xs: "2rem"} }}>
                    02.
                  </Typography>
                  <Typography variant="h5" sx={{ px: 2, py: {xs: 1,md:3}, fontWeight: 600, fontSize:{xs: "1.125rem", md: "1.25rem"} }} color="#414042" align="left">
                    Forje relaciones con los clientes.
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 1.5, px: 2, pb: 2, fontSize:{xs: "1rem"} }} color="#414042" align="left">
                    Facilite una interacción más fructífera con una vista completa del cliente.
                  </Typography>
                  <Divider color="secondary" sx={{ marginLeft: "2vh", width: "30%" }}  />
                </CardContent>
              </Card>
              <Card sx={{ maxWidth: 375, maxHeight: 385, backgroundColor: "#fff" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="h2" color="#3E6369" align="left" sx={{ fontWeight: 600, px: 2, fontSize:{xs: "2rem"} }}>
                    03.
                  </Typography>
                  <Typography variant="h5" sx={{ px: 2, py: {xs: 1,md:3}, fontWeight: 600, fontSize:{xs: "1.125rem", md: "1.25rem"} }} color="#414042" align="left">
                    Trabaje de manera más eficaz.
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 1.5, px: 2, py: 2, fontSize:{xs: "1rem"} }} color="#414042" align="left">
                    Automatice y optimice las tareas diarias con flujos de trabajo de IA.
                  </Typography>
                  <Divider color="darkgrey" sx={{ marginLeft: "2vh", width: "30%" }}  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ 
              display:"flex",
              flexDirection:"column",
              justifyContent:"center",
              alignItems:"center",
              backgroundImage: `url(${imgServicios})`, 
              minHeight: "75vh",
              gap:{xs:2, md:5},
              pt: {xs:2, md:1},
             }}
          >
            <Typography variant="h4" sx={{ fontWeight: 600, fontSize:{xs: "1.25rem", md: "1.5rem"}, py:{xs: 2, md:0} }} color="primary" >
              Servicios que Perduran en el Tiempo
            </Typography>
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Card sx={{ minWidth: 275, backgroundColor: "transparent" }} className={`${styles.borderBottomMovil}`}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h3" color="#fff">
                      <WorkIcon fontSize="large" />
                    </Typography>
                    <Typography variant="h2" sx={{ mb: 1.5, mt: 2, color: "green", fontSize:{xs: "2rem", md: "2.5rem"}  }} color="primary">
                      {counterOn && <CountUp start={0} end={100} duration={4} />}%
                    </Typography>
                    <Typography sx={{ mb: 1.5, fontWeight: 600 }} color="primary">
                      Profesionales Certificados
                    </Typography>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: 275, backgroundColor: "transparent" }} className={`${styles.borderBottomMovil}`}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h3" color="#fff">
                      <QueryStatsIcon fontSize="large" />
                    </Typography>
                    <Typography variant="h2" sx={{ mb: 1.5, mt: 2, color: "green", fontSize:{xs: "2rem", md: "2.5rem"}  }} color="primary">
                      {counterOn && <CountUp start={0} end={100} duration={4} />}+
                    </Typography>
                    <Typography sx={{ mb: 1.5, fontWeight: 600 }} color="primary">
                      Proyectos Realizados
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{ minWidth: 275, backgroundColor: "transparent" }}
                  className={`${styles.borderBottomMovil} ${styles.paddinBottomMovil}`}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h3" color="#fff">
                      <WorkIcon fontSize="large" />
                    </Typography>
                    <Typography variant="h2" sx={{ mb: 1.5, mt: 2, color: "green", fontSize:{xs: "2rem", md: "2.5rem"}  }} color="primary">
                      {counterOn && <CountUp start={0} end={99} duration={4} />}%
                    </Typography>
                    <Typography sx={{ mb: 1.5, fontWeight: 600 }} color="primary">
                      Profesionales Certificados
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </ScrollTrigger>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <Typography variant="h5" align="center" color="#414042" sx={{ px: 5, fontWeight: 700 }}>
              Contamos con un soporte 365
            </Typography>
            <Typography variant="subtitle1" color="#7A7C7F" sx={{ px: 5, mt: 6, fontWeight: 500 }} align="center">
              Contamos con una estructura capaz de darle un servicio eficiente, rápido
              <br />y a su medida.
            </Typography>
          </Grid>
          <Grid container spacing={2} columns={16} sx={{ mt: 10 }}>
            <Grid item xs={8} sx={{ p: 5 }} className={`${styles.gridMovil}`}>
              <Typography variant="h6" color="#414042" sx={{ px: 5, mb: 4 }} className={`${styles.parrafo05Movil}`}>
                Unifique toda su empresa.
              </Typography>
              <Typography variant="subtitle1" color="#7A7C7F" sx={{ px: 5 }}>
                Desde los datos hasta las personas, pasando por los procesos, con aplicaciones modernas e inteligentes
                que se adaptan a las necesidades cambiantes de su negocio.
              </Typography>
              <Typography variant="subtitle1" color="#414042" sx={{ px: 5, py: 3 }}>
                <CheckIcon color="success" /> Soporte 365 días del año
              </Typography>
              <Typography variant="subtitle1" color="#414042" sx={{ px: 5, pb: 3 }}>
                <CheckIcon color="success" /> Eleve tickets con tan solo 1 Clik para que podamos resolver todas sus
                consultas
              </Typography>
              <Typography variant="subtitle1" color="#414042" sx={{ px: 5, pb: 3 }}>
                <CheckIcon color="success" /> Contamos con asesores del mejor nivel certificados por Microsoft
              </Typography>
            </Grid>
            <Grid item xs={8} className={`${styles.accordionContainer}`}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                sx={{ backgroundColor: "#EDF9ED", mb: 3 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#414042" }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography color="#414042" sx={{ py: 2, fontWeight: 600 }}>
                    Unifique su empresa
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="#414042">
                    Conectando personas, procesos y datos a través de Dynamics 365, Office 365, LinkedIn y Azure.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                sx={{ backgroundColor: "#EDF9ED", mb: 3 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#414042" }} />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography color="#414042" sx={{ py: 2, fontWeight: 600 }}>
                    Se ajusta a sus necesidades
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="#414042">
                    Ya que permite integrar las aplicaciones con los sistemas existentes o utilizar Microsoft Power
                    Platform. para personalizarlas y mejorarlas.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                sx={{ backgroundColor: "#EDF9ED", mb: 3 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#414042" }} />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography color="#414042" sx={{ py: 2, fontWeight: 600 }}>
                    Adopte un enfoque moderno
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="#414042">
                    Aproveche la inteligencia artificial, la realidad mixta, las redes sociales y las funcionalidades
                    móviles para agilizar la innovación empresarial.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              px: 4,
              backgroundImage: `url(${imgConfiaNosotros})`,
              minHeight: "30vh",
            }}
          >
            <Typography
              className={`${styles.parrafo06Movil}`}
              variant="h5"
              color="#414042"
              sx={{ fontWeight: "bold", pt: 10, pb: 3 }}
            >
              Clientes que confían en nosotros
            </Typography>
            <Grid item xs={12}>
              <MultiCarrusel />
            </Grid>
          </Grid>
          <Grid
            spacing={2}
            columns={16}
            container
            sx={{
              display: "flex",
              flexDirection: {xs: 'column', md: "row"},
              justifyContent: "center",
              alignItems: "center",
              px: 4,
              mt: 4,
              mb: 5,
              overflow: 'hidden',
            }}
          >
            <Grid
              item
              xs={7}
              sx={{
                backgroundColor: "#fff",
                minHeight: "100%",
                paddingLeft: '0px',
                minWidth: '85vw',
              }}
            >
              <Formulario />
            </Grid>
          </Grid>
        </Box>
        <Box className={`${styles.botonFlotante}`}>
          <IconButton
            onClick={() => {
              window.scroll(0, 0);
            }}
            component="label"
            sx={{
              position: "fixed",
              bottom: "12%",
              right: "5%",
              backgroundColor: "#fff",
              "&:hover": { color: "#fff", backgroundColor: "#517417", borderRadius: "50%" },
            }}
          >
            <KeyboardArrowUpIcon color="secondary" fontSize="large" sx={{ "&:hover": { color: "#fff" } }} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default Home;
