import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import { ThemeConfig } from "./config/theme.config";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import generateStore from "./redux/store/store";
import { BrowserRouter } from "react-router-dom";
const store = generateStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeConfig>
        <Provider store={store}>
          <App />
          <ToastContainer />
        </Provider>
      </ThemeConfig>
    </BrowserRouter>
  </React.StrictMode>
);
